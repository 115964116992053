.migraine_expert_forum{
    background: #5c1284;
    color:#fff;
    padding:4rem 0 10rem 0;
    position: relative;
}
.migraine_expert_forum img{
    max-width:90px;
    margin: -20px 0 0;
}
.migraine_expert_forum h1{
    display: initial;
    margin: 20px;
}
@media only screen and (max-width:767px){
    .migraine_expert_forum h1{
    display: block;
    margin: 20px;
}
}
.migraine_expert_forum a {
    color: #f89410 !important;
}

.migraine_expert_forum h4 span {
    font-size: 15px;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
        width: 100%;
    display: block;
    margin-top: 10px;
}


.migraine_expert_forum .white{
    color:#fff !important
}

.icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon2{
   background: url(../../../../media/images/mhm2022/branding11.png);
   right: -9px;
    bottom: 13vh;
}

@media only screen and (max-width:767px){
  .icon2{
    display:none
}  
}