.wrap{
    background: #d6d6d8;
    color: #1a0a60;
    padding: 3rem;
    text-align: center;
    position: relative;
}

.facultyContainer{
    max-width: 890px !important;
}

.profile_item img{

    max-width: 170px
}

@media only screen and (max-width:767px){
    
    .wrap{
        padding: 20px 0;
    }
}
.wrap object, .wrap svg{
    position: absolute;
    left: 5%;
    top: -90px;
    width: 15%;

}
@media only screen and (min-width:1367px){
    .wrap object, .wrap svg{
        position: absolute;
        left: 0%;
        top: -2%;
        width: 10.5%;
    
    }
}
@media only screen and (max-width:1080px){
    .wrap object, .wrap svg{
    display: none;
    }
}
.wrap .graphical_heading{
    font-size: 2.5rem;
    margin-bottom: 50px;

    margin: 0 !important;
    padding: 95px !important;
    background-position: 45px !important;
}

@media only screen and (max-width:768px){
    .wrap .graphical_heading{
        font-size: 30px !important;
        padding: 45px !important;
        background-position: 15px !important;
    }
}
 
.profile_item h5{
    margin-top: 15px;
    font-size: 18px;
}
.profile_item h3{
    font-size: 24px !important;
}

.profile_item .button{
    border: 1px solid #f61a19 !important;
    color: #f61a19 !important;
    background: #d6d6d8;
    min-width: unset;
    min-height: unset;
    transition: 0.5s;
    cursor: pointer;
}
.profile_item .button:hover{
    border: 1px solid #1a0a60 !important;
    color: #1a0a60 !important;
}
.profile_item{
    margin-bottom: 50px;
    text-align: center;
}

@media only screen and (min-width:1140.1px){
    .profile_item{
        padding:0 5px
    }
}

@media only screen and (max-width:1140px){
    .profile_item{
    margin-bottom: 50px;
    width: 50%;
    flex: unset;
    max-width: unset;
    }
}

@media only screen and (max-width:850px){
    .profile_item{
    margin-bottom: 50px;
    width: 100%;
    flex: unset;
    max-width: unset;
    padding: 15px;
    }
}

@media only screen and (min-width: 1200px){
    .container{
           max-width: 1101px !important;
    padding: 0;
    }
}
.facultyIcon{
    width: 17%;
}
@media only screen and (max-width:1080px){
    .facultyIcon img{
    display: none;
    }
}

.facultyIcon{
  margin-top: -18%;
    position: absolute;
    right: 0;
    width: 12%;
}
@media only screen and (max-width: 767px){
.profile_item img{
    max-width: 250px;
    margin: 0 auto 15px;
}
}