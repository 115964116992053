.slides,.slides_wrap{
    text-align: center;
    }
    .slides img{
        max-width: 120px;
        margin: 15px;
    }
    .slides h2{
    text-align: center;
    font-family:"Gill Sans Light",'Arial','Helvetica' , sans-serif !important;
    }
    .has_custom_border{
        margin-bottom: 30px;
        border-bottom: 2px solid #f61a19;
        padding: 30px;
        position: relative;
    }

.has_custom_border::after

{
    position:absolute;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background: #f61a19;
    border-radius: 50%;
    right: 10%;
    content: '';
}
.has_custom_border:nth-child(3)::after{
    left: 75%;
}

.has_custom_border:nth-child(4)::after{
    left: 25%;
}
 .has_custom_border:nth-child(5)::after{
    display: none;
}
.has_custom_border:nth-child(7){
    border-bottom: none;
}
.has_custom_border:nth-child(7)::after{
    display: none;
}



.comment_section{
    background: #ffffffbf;
    border-radius: 20px;
}

.textarea{
    width: 100%;
    margin: auto;
    min-height: 200px;
    border: none;
    padding: 20px;
    border-radius: 10px;
}
.textarea:focus{
    outline: none;
    border: 1px solid #5c1284;
}