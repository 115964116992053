.practical_workshop{
    background-color:#140061 ;
    color:#fff
}
.practical_workshop h3{
    color:#f89410 ;
}
.practical_workshop h3 span{
    font-size: 15px;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
    font-weight: 100;
}

.practical_workshop h4.red{
    color: #f61a19 !important
}
.practical_workshop hr{
    border-color: #f89410;
}
.practical_workshop h2, .practical_workshop h4{
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
    letter-spacing: 1.1px;
}
.yellow{
    background: #f89410 !important;
}

.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   background: url(../../../media/images/mhm2022/branding7.png);
left: -9px;
    top: 8vh;
    transform: rotateY(180deg)
}
.icon2{
    right:0;
      background: url(../../../media/images/mhm2022/branding3.png);   
   top: 100vh;
}
@media only screen and (max-width:1025px){
  .icon2, .icon1{
    display:none
}  
}