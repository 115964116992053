
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* common css classes */
@media only screen and (max-width: 1144.9px){
    .hide_for_small{
        display: none
    }
    

}
.d-none{
  display:none
}

.logIn_card span.MuiCheckbox-root{
        height: 100%;
    float: left;
    margin-top: 0;
    padding-top: 0px;
    display: block;
}
.travelDetails-section .MuiInputBase-root {
  margin-bottom: auto !important;
}
.MuiDialog-root .MuiPickersToolbar-toolbar,.MuiPickersClock-pin ,.MuiPickersClockPointer-pointer,.MuiPickersClockPointer-thumb{
  background: #1a0a60 !important;
}
.MuiPickersClockPointer-thumb {

  border: 14px solid #1a0a60 !important;;
 
}
.MuiCheckbox-colorSecondary.Mui-disabled svg.MuiSvgIcon-root {
  color: #7a1212;
}
.MuiPickersToolbarButton-toolbarBtn{
  border: none !important;
  box-shadow: none !important;
}
.MuiPickersDay-daySelected:hover, .MuiPickersDay-daySelected{
      background-color: #1a0a60 !important;
        color: #fff !important;
}
.MuiButton-textPrimary {
  color: #1a0a60 !important;
}
li a.active{
  color: #fff !important;
}

@keyframes skeleton-animation
{
  
  0%
  {
    right: 100%;
  }
  
  50%
  {
    right: -128px;
  }
  
  100%
  {
    right: 100%;
  }
  
}

.skeleton
{
 overflow: hidden;
    width: 100%;
    height: 78px;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 8px;
    position: relative;
    margin-bottom: 16px;
}

.skeleton:before
{
     display: block;
    position: absolute;
    width: 128px;
    height: 78px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #320ebf, rgba(255, 255, 255, 0));
    content: "";
    animation: skeleton-animation 1.25s infinite;
}

.skeleton .s-img
{
     height: 78px;
    background: #23078f;
    grid-row: 1 / 4;
}

.skeleton .s-line
{
 height: 20px;
    background: #23078f;
}

.skeleton .s-line.first { width: 100% }

.skeleton .s-line.second { width: 75% }

.skeleton .s-line.third { width: 50% }
.custom_list ul li{
  list-style: none;
  position: relative;
}
.custom_list ul li::after {
  content: " ";
  position: absolute;
  left: -15px;
  background: #f61a19;
  width:5px;
  height: 5px;
  border-radius: 50%;
  top:12px;
}
.universal-control-panel__container.universal-control-panel__container_left{
  display:none !important
}

.logIn_card .MuiFormControl-marginNormal {
  background: #e8f0fe !important;
  height: unset !important;
  min-height: 55px;
}

.confirm-email .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  margin-bottom: 9px;
}

.special-requirements .MuiOutlinedInput-root {
  margin-bottom: 15px !important;
}

.register .MuiOutlinedInput-root {
  margin: unset !important;
  background: #e8f0fe !important;
}

footer .tri-symbol {
  font-size: 12px !important;
}

.custom-height .MuiOutlinedInput-input {
  padding: 0 !important;
  line-height: 0 !important;
  min-height: 61px;
}

.custom-height .view_pw{
  top: 18px !important;
}

.custom-height .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 !important;
}

.custom-height .MuiInputLabel-outlined {
  transform: translate(15px, 24px) scale(1) !important;
}
@media only screen and (max-width:767px) {
  .custom-height .MuiInputLabel-outlined{
    transform: translate(15px, 24px) scale(0.97) !important;
  }
}

.custom-height .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -3px) scale(0.75) !important;
}

.password-strength-bar > div div {
  height: 3px !important;
}

/* .password-strength-bar div {
  margin: 15px 0px 0px !important;
} */

.password-strength-bar p {
  font-size: 20px !important;
  color: #ffffff !important;
}

/* .password-div {
  height: 100% !important;
  max-height: 150px;
} */

.password-div {
  position: relative;
  display: flex;
  flex-direction: column;
}

.password-div .MuiFormControl-root {
  height: 60% !important;
}

.password-area .MuiFormControl-root {
  height: 70% !important;
}
@media only screen and (max-width: 767px){
  .password-area {
    margin-bottom: 10px;
}
}

.reset_password .password-textfield .MuiFormControl-root .MuiOutlinedInput-adornedEnd {
  margin-bottom: 5px !important;
}

.error-div {
  display: flex;
  text-align: right;
  flex-direction: column;
  margin: 10px 0 15px;
}

.reset-code-div .MuiOutlinedInput-root {
  margin-bottom: 15px !important;
}

.disabled {
  pointer-events:none;
}
.disabled .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment{
  display: none;
}
.disabled-non {
  pointer-events:unset !important;
}
