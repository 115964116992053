.custom_budge{
    padding: 0px 5px;
    font-weight: 100;
    margin: 0 5px 5px;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
    font-size: 17.75px;
    line-height: 30px;
}
.bordered_section{
    padding: 20px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #6c757d;
}
.bordered_section button{
    background: #1a0a60 !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    color: #fff !important;
    
}