.wrap{
    background: #1a0a60;
    min-height: 60vh;
    text-align: center;
    color: #fff;
    padding: 35px 0 40px;
    position: relative;
}
.wrap object, .wrap svg{
    position: absolute;
    left: 0;
    top: -3%;
    width: 20.8%;
}
@media only screen and (min-width: 1367px){
    .wrap svg{
        width: 288px;
    }
}
@media only screen and (max-width:1080px){
    .wrap object, .wrap svg{
    display: none;
    }
}
.wrap h3{
    color: #f89410;
}
@media only screen and (max-width:767px){
    .wrap img{
        max-width: 250px;
        margin:0 auto 15px;
    }
}
@media only screen and (min-width: 1200px){
    .container{
        max-width: 890px !important
    }
}

.steeringIcon{
      right: -35px;
    transform: rotate(5deg);
    -webkit-transform: rotate( 5deg);
    margin: 0;
    position: absolute;
}
@media only screen and (max-width:1080px){
    .steeringIcon img{
    display: none;
    }
}

.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   background: url(../../../../media/images/mhm2022/branding7.png);
   right: -9px;
   top: 95px;
}
.icon2{
    left:0;
      background: url(../../../../media/images/mhm2022/branding1.png);   
    bottom: 0;
}
@media only screen and (max-width:1025px){
  .icon2, .icon1{
    display:none
}  
}