.wrap{
  background: #e7e6eb;
    min-height: 60vh;
    text-align: center;
    padding: 11px 0 50px;
    position: relative;
}
.wrap h1{
    margin-bottom: 30px;
}
.container{
    max-width: 890px !important;
}
.steeringIcon{
    right: -35px;
    transform: rotate(5deg);
    -webkit-transform: rotate( 5deg);
    margin: 0;
    position: absolute;
}
@media only screen and (max-width:1080px){
    .steeringIcon img{
    display: none;
    }
}
.graphical_heading{
    margin: 0 !important;
    padding: 95px !important;
    background-position: 45px !important;
}

@media only screen and (max-width:767px){
    .graphical_heading{
        font-size: 30px !important;
        padding: 45px !important;
        background-position: 15px !important;
    }
  }

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 55px !important;
        background-position: 25px !important;
    }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 70px !important;
        background-position: 35px !important;
    }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 60px !important;
        background-position: 25px !important;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 55px !important;
        background-position: 25px !important;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 55px !important;
        background-position: 25px !important;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 55px !important;
        background-position: 25px !important;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 70px !important;
        background-position: 30px !important;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 70px !important;
        background-position: 30px !important;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .graphical_heading{
        padding: 70px !important;
        background-position: 30px !important;
    }
}

.textarea {
      width: 100%;
    margin: 20px auto;
    min-height: 200px;
    border: none;
    background: #fff;
    padding: 20px;
    font-family: 'Gill Sans Medium';
    border-radius: 15px;
    display: block;
}


.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
    
   background: url(../../../media/images/mhm2022/branding9.png);
   right: -9px;
   top: 95px;
}
.icon2{
    left:-40px;
    background: url(../../../media/images/mhm2022/branding8.png);   
     top: 35px;
    transform: scaleX(-1);
}
@media only screen and (max-width:1025px){
  .icon2, .icon1{
    display:none
}  
}