
@font-face{
    font-family:"Gill Sans Light";
    src:url("../media/fonts/Gill-Sans-Light.woff2") format("woff2"),url("../media/fonts/Gill-Sans-Light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
@font-face{
    font-family:"Gill Sans Medium";
    src:url("../media/fonts/Gill-Sans-Medium.woff2") format("woff2"),url("../media/fonts/Gill-Sans-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
@font-face{
    font-family:"Gill Sans SemiBold";
    src:url("../media/fonts/5fb9e481-085f-4ad5-8574-ffabb4dbe7a9.woff2") format("woff2"),url("../media/fonts/cc1cc8a6-92c2-4d62-973e-e4a20c3df0bf.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
@font-face{
    font-family:"Gill Sans Nova W01 Bold";
    src:url("../media/fonts/f5458c7b-8d3d-4e66-a95b-123697bf634d.woff2") format("woff2"),url("../media/fonts/39540fdd-27ee-47ce-b434-ab7dfb6e3d7f.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


html {
  text-rendering: optimizeLegibility !important;
 color: #1a0a60;
  -webkit-font-smoothing: antialiased !important;
   scroll-behavior: smooth;
    font-weight: normal;

}

body {
  margin: 0;
  font-family:Sans-Serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a0a60 !important;
  overflow-x: hidden !important;
     -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
}
h1,h2,h3,h4, p, li{
  font-family:Sans-Serif !important;
}
// FONT FAMILY TYPES

// font-family:'Gill Sans Light';
// font-family:'Gill Sans Medium';
// font-family:'Gill Sans SemiBold';
// font-family:'Gill Sans Nova W01 Bold';
.h1, h1 {
  font-size: 40.75px !important;
}
.h2, h2 {
  font-size: 24px !important;
}
.h3, h3 {
  font-size: 16px !important;
}
@media only screen and (max-width:1365px){
  .h1, h1 {
    font-size: 33.25px !important;
    
  }
  .h3, h3 {
    font-size: 21.5px !important;
  }
} 
@media only screen and (max-width:1365px){
  .h1, h1 {
    font-size: 24.25px !important;
    
  }

}
h1, h2, h3, h4, h5,h6, .h3, .p{
  font-weight: 600;
  font-family:'Gill Sans SemiBold','Arial','Helvetica' , sans-serif !important;
}
b,strong{
  font-family: 'Gill Sans SemiBold','Arial','Helvetica' , sans-serif !important;
  font-weight: normal !important; 
}
p, .p, table td{
  font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
     font-size: 18px;
    line-height: 20px;                                                                                
 
}

.p {
  font-weight: bolder;
}
p.legal_disclaimer{
  font-size: 14px;
}
li{
  font-size: 18px;
  -webkit-text-stroke: 0.1px;
  font-family:'Gill Sans Light','Arial','Helvetica' , sans-serif !important
}
.modal-dialog-centered::before{
  height: auto !important;
}

// Hide BRs on devices for my session and booked brs
@media only screen and (max-width:767px){
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

@media only screen and (min-width: 1080px) and (max-width: 1920px) {
  .h3 br, .p br {
    display: none;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .h3 br, .p br {
    display: none;
  }

  .hide-for-devices {
    display: none;
  }

  .altContact-h2 br {
    display: none;
  }
}

//containers

//start flex containers
@media only screen and (min-width: 1366.1px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px !important;
}
}
@media only screen and (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 920px !important;
}
}
@media only screen and (min-width: 1200px) {

 #Welcome .container {
    max-width: 1070px !important;
  }
}
#Welcome .container a {
  text-decoration: underline;
  cursor: pointer;
}
.flex-row-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px;
  width: 100%;

  .x-units-gap-child {
    flex: 1 1;
    border: 1px red solid;
    text-align: center;
    margin: 5px; /* and that, will result in a 10px gap */
  }

}

.flex-fluid-row-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  width: 100%;

  .x-units-gapped-child {
    flex: 1 1 auto;
    box-shadow: inset 1px 1px 1px 1px #152939;
    text-align: center;
    //margin: 5px; /* and that, will result in a 10px gap */
  }
}

.flex-column-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  width: 100%;

  .x-units-gap-child {
    flex: 1 1 auto;
    border: 1px red solid;
    text-align: center;
    margin: 5px; /* and that, will result in a 10px gap */
  }
}

.flex-fluid-column-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5px;
  width: 100%;

  .x-units-gap-child {
    flex: 1 1 auto;
    border: 1px red solid;
    text-align: center;
    margin: 5px; /* and that, will result in a 10px gap */
  }
}

// end flex containers

// pop upcss
.modal-dialog{
  min-width: 50% !important;
  margin: auto !important;
  position: fixed;
  left: 20%;
  width: 56%;
 &::after{
  height: auto !important;
 }
}
@media only screen and (max-width:1366px){
    .modal-dialog{
        min-width: 70% !important;
      }
}
@media only screen and (max-width:800px){
  .modal-dialog{
    min-width: 100% !important;
    left: 2.5%;
  }
  .m-text-left{
    text-align: left !important;
  }
}
.modal-dialog tbody tr:nth-child(odd) {
  background: #eaeaea !important; 
}
@media only screen and (max-width:1144px){
  .modal-dialog tbody tr:nth-child(odd){
    background: #fff !important;
  }
}
.modal-body th {
    background: #1a0a60;
    color: #fff;
}
@media only screen and (max-width: 1144px){
  .modal-body .hide_large th{
    background: none;
    color: #1a0a60;
  border: 1px solid #dee2e6 !important;
}


  .modal-body .table:nth-child(odd){
    background: #ededed;
  }
  .modal-body .table-bordered th {
    width: 20% !important;
}
 .modal-body .hide_large .table-bordered th{
   background: #eae7f7;
 }
  .hide_large{
display: block;
  }
    .hide_small{
    display: none;
  }
}
@media only screen and (min-width: 1144.1px){
 
  .hide_large{
display: none;
  }
  .hide_small{
    display: block;
  }
}
.modal-body h3, .modal-body ul{
text-align: left !important;
}
.modal-body h3 {
  margin-bottom: 25px;
}

.modal-body .table td {
}

.modal-body .table td,.modal-body .table th{
  max-width: 211px;
  padding:12px;
  font-size: 18px;
  font-family:"Gill Sans Light",'Arial','Helvetica' , sans-serif !important;
}
.modal-body td strong {
  font-weight: bold !important;
}
.modal-body{
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;      
  scrollbar-color: #f89410 #fff;
  scrollbar-base-color: #fff;
  scrollbar-face-color: #f89410;
  scrollbar-3dlight-color: #fff;
  scrollbar-highlight-color: #fff;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: #fff;
  scrollbar-shadow-color: #fff;
  scrollbar-dark-shadow-color: #fff;
  padding: 20px !important;
  min-height: 600px;
}
@media screen\0 {
  .modal-body{
     max-height: none !important;
     min-height: auto !important;
  }
}
@media only screen and (max-width:1366px){
  .modal-body{
    max-height: 400px;
    min-height: unset;
  }
}
.modal-body::-webkit-scrollbar {
  width: 20px;         
}
@media only screen and (max-width: 600px){
  .modal-body::-webkit-scrollbar {
    width: 6px;         
  }
}
.modal-body::-webkit-scrollbar-track {
  background: #fff;  
  border: none;     
}
@media screen\0 {
  .modal-body table{
    margin-bottom: 100px;
  }
}

.modal-dialog{
  min-width: 50%;
  max-width: 100% !important;
  display: block !important;
  top: 22%;
}
.modal-dialog ul li{
  font-size: 18px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #f89410;   
  border-radius: 20px;       
  border: none;  
}
.custom-modal-content {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: unset;
  outline: 0;
  height: 52%;
  overflow: auto;
  position: absolute;
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  border: 1px solid #bbbabd;
    border-bottom: 0;
}
@media only screen and (max-width:800px){
  .custom-modal-content
  {
    width: 95%;
  }
}
.custom-modal-content p {
    word-spacing: 2px;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  background: #fff;
  background-clip: padding-box;
  outline: 0;
  min-height: unset;
  max-height: unset;
  overflow: scroll;
  border-radius: 30px 30px 0 0;
}
.modal-footer {
  text-align: center;
  justify-content: center !important;
  position: fixed;
  background: #ffffff;
  bottom: 16%;
  width: 96%;
  left: 2%;
  margin: 0;
  height: 15vh;
  border: 1px solid #bbbabd;
  border-top: 0;
  padding: 0 0 15px;
  border-radius: 0 0 30px 30px;
}
@media screen\0 {
  .modal-footer {
  position: absolute;
  border:none;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 15vh;

  }
}
@media only screen and (max-width:800px){
  .modal-footer {
    width: 95% !important;
    left: 2.5%;
    button {
      padding: 10px 40px;
  }
  }
}
.pop-up-wrap{
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(../media//images/popup-bg-0.png);
    background-repeat: repeat;
    z-index: 999999;
    top: 0;
    padding: 20px;

}
@media only screen and (max-width:768px){
  .pop-up-content {
    margin: 45% auto 0 !important;
    
}
}
.pop-up-content{
  max-width: 600px;
  margin: 10% auto;
  text-align: center;
  background: #5c1284;
  padding: 50px 30px 20px;
  color: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: pop-up 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  
}
@keyframes pop-up {
  0%{
      opacity: 0;
      margin: 10% auto;
  }
  100%{
      opacity: 1;
      margin: 15% auto;
  }
}
.pop-up-content .btn{
  margin: 10px;
  padding: 10px 30px !important
}
// OVERIDE MATERIAL UI FORMS
// PRACTICAL WORKSHOP FIELDS
.practical_workshop .MuiRadio-root{
    border-color: white !important;
    background: #fff !important;
    color: #fff !important;
    padding:2px
}
.practical_workshop .MuiSvgIcon-root{
     color: #f89410;
    font-weight: bold;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.fully_booked {
    opacity: 0.5;
}
.fully_booked .MuiSvgIcon-root{
     color: #fff !important;
}
.migrain_expert_forum .MuiRadio-root{
      float: left;
    margin: 0 20px;
    background: #fff !important;
}

// END PRACTICAL WORKSHOP FIELDS
// CONTACT US PAGE

.contact_us .MuiOutlinedInput-root{
        border-radius: 6px !important;
    background: #fff !important;
    border: none !important;
}
.contact_us .MuiOutlinedInput-root textarea{

    min-height: 200px;
}
.contact_us .MuiFormLabel-root {
   
    text-align: left;
    text-transform: initial;
}
.contact_us .PrivateNotchedOutline-legendLabelled-7 > span{
      max-width: 100px;
}
// END CONTACT US PAGE
.MuiSvgIcon-root{
  color: #1a0a60;
}
.error{
  background: #961339;
    padding: 10px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    p{
      margin-bottom: 0;
    }
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
.Mui-error{
  position: relative;
  
}
.MuiInputBase-root.Mui-error::after{
  content: 'This field is required';
  position: absolute;
  right: 0;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 32px;
  top: -16px;
  background: #ef6e66;
  color: #14045f;
  left: unset;
  height: fit-content;
}
.hide{
  display:none
}
.invalid_email .MuiInputBase-root.Mui-error::after{
  content: 'Email is invalid';
}
.adminSwitch span{
  font-size: 18px !important;
  color: #fff;
}
.adminSwitch label{
 min-width: 125px;
    margin: 0;
    padding: 5px 0;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1a0a60 !important;
}
.register .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fff !important;
}
.MuiOutlinedInput-input {
  padding: 18.5px 18px;
  font-size: 12px;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(18px, 20px) scale(1);
  pointer-events: none;
  text-transform: uppercase;
  font-size: 18px !important;
}
.MuiOutlinedInput-root {
  margin: 0 0 25px 0;
  background: #e6eff2;
  border-radius: 100px !important;
}
.MuiInputLabel-root {
  color: #1a0a60 !important;
  font-family: 'Gill Sans Medium' !important
}
.MuiDialog-root .MuiButton-textPrimary:hover {
  color: #3f51b5 !important;
}
.has_date_time .MuiInputLabel-shrink, .MuiInputLabel-outlined.MuiInputLabel-shrink, .MuiInputLabel-shrink {
  transform: translate(18px, -6px) scale(0.7);
  background: #1a0a60;
  width: auto !important;
  padding: 5px 15px !important;
  border-radius: 19px !important;
  color: #fff !important;
  display: inherit;
}
.slick-track .MuiInputLabel-shrink{
  top: -5px !important;
  text-transform: uppercase;
}
.has_date_time .MuiInputLabel-shrink{
min-width: 200px;
  padding: 6px !important;
  top: -5px !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after{
  border: none !important;
}
.MuiIconButton-root:hover {
  color: initial !important;}
.MuiFormControl-root{
  height: 100% !important;
  margin-bottom: 0 !important;
  background: none !important;
  width: 100%;
}
.reset_password .MuiFormControl-root{
  height: auto !important;

}
.slick-list {
  margin-bottom: 100px;
  transition: 0.5s;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus{
    text-decoration: none !important;
      color: #fff !important;
      border-style: outset;
          background: #1a0a60;
      box-shadow: inset 0px 0px 0px 5px #5c1284;
}
.slick-dots li {
  height: 25px;
  width: 25px;
  padding: 3px;
}
.slick-dots li button{
      font-size: 17px;
      line-height: 10px;
      color: #fff;
}
.slick-dots li button:before{
  display: none;
}

.slick-dots li.slick-active{
    background: #1a0a60;
      border-radius: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.error_message{
  position: absolute;
  right: 15px;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 32px;
  top: -16px;
  background: #ef6e66;
  color: #14045f;
  font-family: 'Gill Sans SemiBold', "Arial" !important;
}
.password-area .error_message{
  top: 8px;
}
@media only screen and (max-width:767px){
  .error_message{
    position: absolute;
    right: 15px;
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 32px;
    bottom: -9px;
    top: unset;
  }
  .password-area .error_message{
    top: unset;
  }

  
}
.custom_mui_forms .MuiOutlinedInput-root, .logIn_card .MuiFormControl-marginNormal{
  border-radius: 5px !important;
  background-color: #fff !important;
}
.logIn_card .MuiInputBase-root{
      width: 100%;
        color: #140060;
}
@media only screen and (max-width:767px){
  .MuiInputLabel-formControl{
    font-size: 14px !important;
  }
}
.logIn_card .MuiInput-underline:before{
  border: none;
}
.logIn_card .MuiFormControl-marginNormal{
  border-radius: 55px !important;
  display: block;
}
.MuiInputBase-input{
  text-align: center;
}
.select-users .MuiCheckbox-root{
  height: 100%;
  float: left;
  margin: 0 5px 0 0;
  padding: 0;
}
.comment_section .MuiOutlinedInput-inputMultiline{
  min-height: 240px;
}
.comment_section .MuiOutlinedInput-root{
  background: #fff;
  border-radius: 10px !important;
}
.comment_section .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #5c1284;
  border-width: 2px;
}
.comment_section .MuiInputLabel-outlined{
  text-align: left !important;
  text-transform: inherit;
    font-size: 16px !important;
}
// GLOABL CSS
button:focus {
  outline: 1px dotted;
  outline: none;
}
.CookieConsent a{
  color:#f89410 !important;
 
}
.CookieConsent{
  background: #1a0a60 !important;
  border: none !important;
  padding: 0 30px;
  &.public-user {
  bottom: 100px !important;
  z-index: 999999 !important;
}
  button{
    background: #f89410 !important;
        padding: 10px 40px !important;
    &:hover{
     
        box-shadow: inset 0px 0px 0px 5px #f61a19 !important;
        
  
    }
  }
}
@media only screen and (max-width:767px) {
.CookieConsent {
  button {
    padding: 10px 12px !important;
      width: 100%;
      font-size: 11px !important;
      margin: 10px 0 !important;
  }
}
}
.underline a{
  text-decoration: underline !important;
  color:#f89410 !important;
  cursor: pointer;
  font-weight: 600;
}
object{
  height: 100%;
}
a:hover {
  color: #f89410 !important;
  text-decoration: none !important;
}
.page-wrapper{
  min-height: 92.6vh;
}

.min-vh-70{
  min-height: 70vh;
}
.min-width-unset{
  min-width:unset !important
}
@media only screen and (max-width:767px){
  .margi-auto-mobile{
    margin: auto !important;
  }
}
.button,.btn, button{
  border: none !important;
  color: #fff ;
  padding: 10px 40px;
  border-radius: 50px !important;
  background: #1a0a60;
  display: inline-block;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: 'Gill Sans Medium','Arial','Helvetica' , sans-serif;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}
.button:hover,.btn:hover, button:hover{
  text-decoration: none !important;
  color: #fff !important;
  border-style: outset;
  box-shadow: inset 0px 0px 0px 5px #5c1284;
}
@media only screen and (max-width:600px){
  .button,.btn{

    min-width: unset;
  }
}
.button.red, .btn.red{
  background: #f61a19;
  
}
.button.red:hover, .btn.red:hover{
  box-shadow: inset 0px 0px 0px 5px #f89410;
  
}
.button.orange, .btn.orange{
  background: #f89410;
  
}
.button.purple, .btn.purple{
  background: #5c1284;
  
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: inset 0px 0px 0px 2px #5c1284;
}
.button.purple:hover, .btn.purple:hover{
  box-shadow: inset 0px 0px 0px 5px #f89410;
  
}
.bordered{
  background: none;
  box-shadow: inset 0px 0px 0px 5px #1a0a60;
  color: #1a0a60;
}
.bordered:hover{
  color: #5c1284 !important;
}
.graphical_heading{
  background: url(../media/images/heading_bg.png);
  background-repeat: no-repeat;
  display: inline-table;
  background-size: contain;
  padding: 50px;
  background-position: 0px center;
  margin:0 0 40px 0 !important;
  font-size: 55px;
}
.register .graphical_heading{
    background: url(../media/images/Registrationtitle.png);
      background-repeat: no-repeat;
      display: inline-table;
      background-size: contain;
      padding: 50px;
      background-position: 0px center;
      font-size: 37px !important;
}
@media only screen and (max-width:767px){
  .register .graphical_heading{
    background-repeat: no-repeat;
      background-size: 62%;
      padding: 35px;
      font-size: 26px !important;
  }
}
.top_section .graphical_heading{
  margin:5% 0 40px 0 !important;
}
.graphical_heading.purple{
  background: url(../media/images/purple_heading_bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px center;
}
.graphical_heading.orange{
  background: url(../media/images/CME_asset/heading_bg.png);
  background-repeat: no-repeat;
  background-size: 60%;
  padding-left: 70px;
  background-position: center left;
  margin: 0% 0 40px -4% !important;
}

@media only screen and (max-width:580px){
  .graphical_heading{
    font-size: 30px;
    background-size: contain;
    padding: 20px 30px;
  }
}


.faculty_graphical_heading{
  background: url(../media/images/Faculty-recommends.png);
  background-repeat: no-repeat;
  display: inline-table;
  background-size: contain;
  padding: 50px;
  background-position: 0px center;
  margin:0 0 40px 0 !important;
  font-size: 55px;
}
@media only screen and (max-width:580px){
  .faculty_graphical_heading{
    font-size: 30px;
    background-size: contain !important;
    padding: 20px 30px !important;
  }
}
@media only screen and (min-width:1081px){
  .hide-for-desktop{
    display: none !important;
  }
}
/*************** Others ***************/
.admin-table .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  min-width: 190px;
  font-size: 13px;
  margin:0
}
.admin-table .MuiInputBase-root{
  margin-bottom: 0;
}
.admin-table .MuiFormControl-marginNormal{
  margin-top: 0 !important;
}
.header_aection{
  
 padding:10px;
  .adminSwitch{
    margin-top: 30px;
     width:auto;
  }
  .menu_section{
    margin-top: 30px;
     width:auto;
     float: right;
  }
  .menu_section li{
  display:inline;
  }
  .menu_section li a{
    padding:10px;
    margin:10px;
    border: solid 1px #333;

  }
}

.admin-table .MuiInputLabel-outlined.MuiInputLabel-shrink, .admin-table .MuiInputLabel-animated{
      font-size: 13px !important;
        padding: 10px !important;
        top: -10px !important;
}
 .admin-table .MuiInputLabel-animated{
  padding: 0 !important;
 }
.admin-table td, .admin-table th{
  min-width: 250px;
}
.admin-table .MuiFormControl-marginNormal .MuiInputLabel-animated{
      font-size: 14px !important;
}

#Interactive-Migraine-Management-Workshop, #Meet-The-Expert,#Migraine-Expert-Forum{
  scroll-margin: 170px;
}
#Migraine-Education-On-Demand{
  scroll-margin: 150px;
}
#SteeringCommittee{
  scroll-margin: 130px;
}
img {
  width: 100%;
}



.view_pw{
  position: absolute;
  right: 30px;
  top: 15px;
}
  .forgot_pw{
    span{
      a{
        color: #00617f;
        font-size: 18px;
        font-family: 'Gill Sans Light'
      }
    }
 
  }
 .page-footer {
  h5{
    text-align: center;
    color: #1a0a60;
    margin: 35px 0;
  }
  p{
        color: #000;
    font-size: 12px !important;
    line-height: 15px;
    margin-top: 7px
  }
 
 }
 
footer{
  background: #fff;
  font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif;
    padding: 5px 25px 0;
  position: relative;
  .footer-copyright { 
    
    @media only screen and (max-width:991px) {
      text-align: center;
      ul{
         @media only screen and (max-width:991px) {
      margin: auto !important;
      float: unset !important;
         }
      }
  
  }
  img{
       max-width: 80px;
    width: unset;
    margin: 5px 0;
  }
  ul{
    margin: 8px 0;
    padding: 0;
    @media only screen and (max-width:600px) {
     float: none !important;
    margin: auto;
    padding: 0;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  }
}
  span{
    color: #1a0a60;
    font-size: 18px;
    @media only screen and (max-width:767px) {
      font-size: 12px;
  }
  }
  ul{
    li{
      list-style: none;
      font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif;
      float: left;
      margin: 0 5px;
      color: #1a0a60;
       font-size: 12px;
    line-height: 15px;
      width: 100%;
        @media only screen and (max-width:767px) {
            font-size: 12px;
        }
      a{
        color:#000 !important;
        font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif;
      }
    }
  }
}


label{ 
  width: calc(100% - 30px);
  text-align: center;
  color: #00617f !important;
  font-size: 18px !important;
}
@media only screen and (max-width: 600px){
  label{ 

    font-size: 18px !important;
    width: calc(100% - 30px) !important;
  }
}






// START ADMIN CSS

.nav-link {
  cursor: pointer;
  display: flex !important;
  color: #1a0a60 !important;
  .MuiSvgIcon-root {
    width: 30px;
    height: 40px;
    margin-right: 20px;
    color: #1a0a60;

}
}

.admin{
  &.header {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: #f61a19;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #fff;
  }

 
}
.sidebar {
  position: fixed;
  z-index: 999;
  left: 0;
  overflow-x: hidden;
  top: 50px;
  height: 100%;
  width: 60px;
  background: #fff;
  border-right: 1px solid #ddd;
  text-align: left;
  transition: all .2s ease-in-out;

 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  ul li {
    color: #00617f;
    display: flex;
    transition: .5s;
    &:hover{
      background: #f61a19;
      a,span, svg{
        color: #fff !important;
      }
    }
    a{
      &.active{
        color:#fff !important
      }
    }
  }
  &.active,&.hovered,&:hover{
    width: 250px;
    transition: all .2s ease-in-out;
    color: #00617f;
  }
}


.main {
  position: relative;
  display: block;
  top: 50px;
  left: 0;
  padding: 15px 15px 15px 75px;
  transition: all .2s ease-in-out;
}

.main tr:nth-child(odd) td {
  background: #eeeaff;
}
.main .custom_mui_forms tr:nth-child(odd) td {
  background: unset;
}
.header #menu-action {
  display: block;
  float: left;
  width: 60px;
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  background: rgba(0,0,0,.15);
  font-size: 13px;
  transition: all .2s ease-in-out;
}
.header #menu-action:hover {
  background: rgba(0,0,0,.25);
}



// ADMIN SIDE CSS
.admin_buttons_wrap{
  position: fixed;
  top: 20px;
  background: #5c1284;
  width: 100%;
  left: 0;
  z-index: 9;
}
.admin_buttons_wrap .button {
  background: #f89410;
  float: revert;
}


@media only screen and (max-width:767px){
  footer ul{
    float: none !important;
    padding:0;
    margin:0 
  }
}
.error {
  margin:10px 0 0 20px
}
@media only screen and (max-width:767px) {
.error {
      margin: 13px 0 0 20px;
        font-size: 13px;
        display: block;
  }
}
.error p {
    margin-bottom: 0;
    color: #fff;
}

  ::-webkit-scrollbar{
        height: 10px;
        width: 10px;
        position: fixed;
        bottom:0;
        background: #fefefe;
    }
      ::-webkit-scrollbar-thumb{
        background: #ff9400 !important;
        border-radius: 5px;
      }
    ::-webkit-scrollbar-thumb:horizontal{
  
    }

   .admin-table .Component-horizontalScrollContainer-12 div div:nth-child(1) {
    max-height: 70vh;
}
.slick-disabled {
  display: none !important;
}

.privacy_table_auto{
  overflow: auto;
}
.privacy_table table {
     width: 100%;
     border-collapse: collapse;
   }

   .privacy_table th,
   .privacy_table td {
        text-align: left;
          padding: 13px;
          background: white;
          vertical-align: baseline;
   }

   .privacy_table th {
     background-color: #f2f2f2;
   }

   .privacy_table tr:nth-child(even) {
     background-color: #f2f2f2;
   }

 @media screen and (min-width: 601px) {
              .hide-for-large {
                display: none;
              }
    }

   @media screen and (max-width: 600px) {
.hide-for-small{
  display: none;
}
   }