

@keyframes fadeInRight{
    0%{
        right:-100%
    }
    100%{
        right: 0
    }
}
.alert-wrapper{
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    text-transform: uppercase;
}

.alert{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    margin: auto;
    max-width: 30%;
    padding:0px;
    border: none;
    border-radius: inherit;
}

.alert-success {
    position: fixed;
    top: 20px;
    right: -100%;
    left: auto;
    z-index: 1001;
    margin: auto;
    max-width: inherit !important;
    min-width: inherit !important;
    transform: inherit;
    padding: 0px;
    border: none;
    border-radius: inherit;
    background-color: #5cc360;
    animation: fadeInRight 1s;
    animation-fill-mode: forwards;

}

.alert-danger .message-body{
max-height: 300px;
overflow-y: auto;
}
.alert-danger .message-body p{
    text-align: left;
}


.alert-request{
    padding: 20px;
}
.alert-success, .alert-danger{
    color: #333;
   
    text-align: center;
 
    overflow-y: auto;
}

.alert-danger{
    min-width: 100%;
    background-color: #ccc;
   
}


.alert .wrapper{
    padding:15px;
}
.alert .icon{
    font-size:40px;

}
.alert-success .icon{
    color: #fff;
    vertical-align: middle;
    padding: 10px;
  ;
}
.alert-danger .icon{
 
    color: #777;
    
}
.alert h1{
 
    font-size:18px;
    margin-bottom:15px;
}
.alert-danger h1{
    color: #777;
  
}
.alert-success h4, .alert-success a{
color:#fff !important;

}
.alert-danger h4{
    padding-top:10px;
    color: #777
}
.alert-danger a.btn.btn-secondary{
    margin-top:10px;
    background-color: #777;
    color:#fff;
}
.close {
    font-size: 11px;
    line-height: 1;
    position: absolute;
    right: 6px;
    top: 2px;
    opacity: 1;
    border: none;
    height: 25px;
    width: 25px;
    color: #fff;
   
}
.logout{
    font-weight: bold;
    font-size: 11px;
}
.close:hover, .close:visited{
    opacity: 1;
    color: #fff
}
.alert-info{
    background: #fff;
}
.alert-info a{
    color: #000;
}
.alert-info .btn-success{ margin: 0 0 0 10px; background: inherit;}
.alert-info .btn-secondary{ background: #ddd;}