html,body {
  
  font-family:Sans-Serif;
 
}
h1,h2,h3,h4, p, li{
  font-family:Sans-Serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
