.wrap{
    background: #E7E6EA;
}
.wrap h1{
  
    margin: 15% auto 40px auto !important;
}
@media only screen and (min-width:1024px){
    .wrap .graphical_heading {
        padding: 50px 0 50px 90px !important;
    }
}

.wrap h1{
    width: auto;
    background-position: -17% !important;
}
@media only screen and (max-width:768px){
   
    .wrap h1{
       
        padding: 45px 8px;
        background-position: -12% 32% !important;
        background-size: 44% !important;
    }
 
    
    
  }