.podcast,.podcast_wrap{
    text-align: center;
    }
    .podcast img{
        max-width: 120px;
        margin: 15px;
    }
    .podcast_wrap img{
        -ms-interpolation-mode: bicubic;
    }
    .podcast h2{
    text-align: center;
    font-family:"Gill Sans Light",'Arial','Helvetica' , sans-serif !important;
    }
    .podcast_item{
        border-bottom: 2px solid #f89410;
    }

  
.download_btn{
    color: #5c1284;
    box-shadow: inset 0px 0px 0px 2px #5c1284;
}
.neuro_logo{
    background: url('../../../../media/images/migraine_education_on_demand/podcast/podcast_logo@2x.png');
    min-height: 295px;
    width: 100%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
}
