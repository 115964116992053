.hidden{
  display:none;
}
.regular-user-header{
  position: inherit;
}
.regular-user-top-section {
  min-height: 300px;
  background: #259ad8;
  img{
    width: 150px;
  }
  h3,p{
    color: #fff;
  }

}
.regular-user-wrapper{
  .tile {
    min-height: 200px;
    background: #dfdede;
    margin-top: 30px;
    padding: 20px;
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      box-shadow: -6px 35px 17px -37px #727171;
      margin-top: 25px;
      background: #269ad8;
      color: #fff;

      i{
        background: #06354e;
      }
    }
  }
  i {
    background: #269ad8;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    padding: 23px 23px;
    color: #fff;
    transition: 0.3s;
    svg {
      height: 30px;
      width: 30px;
    }
  }
}