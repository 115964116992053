.cme_accreditation {
  background-color: #e7e6eb;
}

.cme_section {
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 890px !important;
  }
}

.icon1,
.icon2 {
  position: absolute;
  width: 10vw;
  height: 35vh;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.icon1 {

  background: url(../../../media/images/mhm2022/branding9.png);
  right: -9px;
  top: 95px;
}

.icon2 {
  left: -40px;
  background: url(../../../media/images/mhm2022/branding8.png);
  /* top: 35px; */
  transform: scaleX(-1);
}

@media only screen and (max-width:1025px) {

  .icon2,
  .icon1 {
    display: none
  }
}

/* .icon1{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   
     background: url(../../../media/images/mhm2022/branding7.png);
   right: -9px;
   top: 42vh;
}
@media only screen and (max-width:767px){
  .icon1{
    display:none
}  
} */