.cofirmationMessageWrap{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    z-index: 999999;
    top: 0;
}
.content{
    max-width: 600px;
    margin: 10% auto;
    text-align: center;
    background: #5c1284;
    padding: 50px 30px 20px;
    color: #fff;
    border-radius: 10px;
    opacity: 0;
    animation: pop-up 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    
}
@keyframes pop-up {
    0%{
        opacity: 0;
        margin: 10% auto;
    }
    100%{
        opacity: 1;
        margin: 15% auto;
    }
}