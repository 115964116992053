.header {
     background: #f61a19;
    width: 100% !important;
    transition: 0.5s;
    padding: 0px 0 0px;
    box-shadow: 0 -18px 27px 4px #060101;
    z-index: 9999;
    float: left;
    position: fixed;
 
}
a{
      color: inherit;
    text-decoration: none;
}
.logo {
	float: left;
	background: url(../../media/images/logo.png) no-repeat;
  width: 35px;
  height: 50px;
  transition: 0.5s;
  margin: 0px 0 0 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
 .logo{
   margin-top: -5px;
 }
}
@media only screen and (max-width:1144px){
 .logo{
   margin-top: 15px;
 }
}
.menu_section {
  float: right;
  margin: -5px 15px;
  padding: 0;
}

.menu_section li.button,.menu_section li a.button, .menu_section li, .menu_section ul li.menu_bar {
	float: left;
    margin: 0px 5px;
    font-size: 14px;
    padding: 7px 40px;
    list-style: none;
    border-radius: 50px;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif;
   
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}
.menu_section li {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width:1144px){
  .menu_section li{
    margin: 5px 0 !important 
  }
}

.menu_section li.register a{
  color: #1a0a60 ;
}
.menu_section li.register a.active{
  color: #fff !important ;
}
.menu_section li.log_in a{
  color: #fff !important;
  padding: 5px 30px;
  margin: 5px 0
}
.register a{
  border: 2px solid;
  transition: 0.3s;
}
.register a:hover, .register a.active{

  color: #fff !important;
  font-size: 18px;
}

@media only screen and (max-width:1144px){
  .register {
    display: none;
  
  }
}
.log_in a {
  background: #1a0a60;
  color: #fff;

}
@media only screen and (max-width: 1144px){
.log_in {
	display: none;

}
}
.log_in a{
	color: #fff !important
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 3px;
  background-color: #1a0a60;
  margin: 5px 0;
  transition: 0.4s;
  display: block;
  transition: 1s;
  opacity: 1;
}
.menu_icon{
  padding: 15px 40px 0;
  min-width:175px ;
}
@media only screen and (min-width:1245px){
  .menu_icon{
    display: none;
  }
}
.menu_icon li{
  padding: 0;
  margin: 0px;
  float: none;
}

/* begin main nav css */
.menu{
  opacity: 1;
}
.menu.show .main_nav{
  background: #f89410;
  position: fixed;
  min-width:30%;
  height: 100vh;
  top: 0;
  right: -100%;
  opacity: 0;
  animation: mainNav 0.2s;
  animation-fill-mode: forwards;
  z-index: 9;
  overflow-y: auto;
}
@keyframes mainNav{
  0%{
    opacity: 0;
    right:-100%
  }
  100%{
    right:0;
    opacity: 1;
  }
}
@media only screen and (max-width:1144px){
  .menu.show .main_nav{
    width: 100%;
  }
}
.menu.hide .main_nav{
  background: #1a0a60e8;
  position: fixed;
  min-width: 100%;
  height: 100vh;
  top: 0;
  right: -100%;
  opacity: 0;
  animation: reverseMainNav 0.5s;
  animation-fill-mode: forwards;
  z-index: 999;
  display:none;
}
@keyframes reverseMainNav{
  0%{
    right:0;
    opacity: 1;
  }
 
  100%{
   
    opacity: 0;
    right:-100%
  }
}


.show{
   margin : 0;
}
.hide{
margin: 0;
  

}
.menu.hide .adminSwtich{
    position: absolute;
    right: 0;
    width: unset;
    top: 100%;
    background: #333333;
  padding: 0 10px;
    border-radius: 0px 0 0 5px;
    box-shadow: 4px 7px 15px -7px #333333;
    transform: rotate(0deg);;
}
.menu.show .bar1{
  transform: rotate(40deg);
  width: 30px;
  margin: 15px 0 0 0px;
}
.menu.show .bar2{
  opacity: 0;
}
.menu.show .bar3{
  transform: rotate(-50deg);
  width: 30px;
  margin: -15px 0px 0 0;
}
.menu.show .main_nav ul{
  padding: 0 0% 0 15%;
  text-align: center;
  margin: 15% auto 0;
}
@media only screen and (max-width:1144px){
  .menu.show .main_nav ul{
    width: 100%;
    padding: 0 0% 0 5%;
    margin: 0;
  }
}

.menu.show .main_nav ul li{
  font-weight: 600 ;
  float: none;
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 10px 0px;
  transition: 0.2s;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  text-align: left;
  color: #1a0a60 ;
  font-family: 'Gill Sans Medium','Arial','Helvetica' , sans-serif !important;
}
@media only screen and (max-width:600px){
  .menu.show .main_nav ul li{
    font-size: 20px;
    padding: 20px 15px;
  }
}

.menu.show .main_nav ul li.has_dropdown ol{
  margin: 0 40px;
  padding: 0 0;
  max-width: 345px;
  display: block;
  height: 0;
  transition: 1s;
  overflow: hidden;
  visibility: hidden;
}

.menu.show .main_nav ul li.has_dropdown{
  position: relative;
}
.menu.show .main_nav ul li.has_dropdown.active ol{
  display: block;
  height: auto;
  visibility: hidden;
  margin-top: 0;
  overflow: unset;
  opacity: 0;
  animation: animateNav 0.5s;
  animation-fill-mode: forwards;
}
@keyframes animateNav{
  0%{
    height: auto;
    visibility: hidden;
    margin-top: -52%;
    overflow: unset;
    opacity: 0;
  }
 50%{
  visibility:visible;
  margin-top: 0;
  overflow: unset;
  opacity: 0;
  margin-left: 10px;
 }
  100%{
    visibility:visible;
    margin-top: 0;
    overflow: unset;
    opacity: 1;
    margin-left: 40px;
  }
}


@keyframes reverseAnimateNav{
  0%{
   
    height: auto;
    visibility:visible;
    margin-top: 0;
    overflow: unset;
    opacity: 1;
    margin-left: 40px;
  }
  25%{
    visibility:visible;
    margin-top: 0;
    height: auto;
    overflow: unset;
    opacity: 0.8;
    margin-left: 80px;
  }
 50%{
  visibility:visible;
  margin-top: 0;
  height: auto;
  overflow: unset;
  opacity: 0.6;
  margin-left: 120px;
 }
 75%{
  visibility:visible;
  margin-top: 0;
  height: auto;
  overflow: unset;
  opacity: 0.4;
  margin-left: 160px;
 }
  100%{
    height: 0;
    visibility: hidden;
    overflow: hidden;
    margin-left: 180px;
    opacity: 0;
    display: none
  }
}
.menu.show .main_nav ul li.has_dropdown::after{
  position: absolute;
  content: '';
  right: 65px;
  top: 0;
  transition: 0.3s;
  background: url(../../media/images/menu_icon.png);
  width: 20px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  top: 15px;
}
.menu.show .main_nav ul li.has_dropdown:hover::after{
color: #fff;
}
.menu.show .main_nav ul li.has_dropdown.active::after{
  transform: rotate(90deg);
}

.menu.show .main_nav ul li.has_dropdown ol li{
  list-style-type: decimal;
  font-family:'Gill Sans Light','Arial','Helvetica' , sans-serif;
  padding: 5px;
  
}
.menu.show .main_nav ul li.has_dropdown ol li:last-child(){
list-style-type: none;
}
.menu.show .main_nav ul li a{
  transition: 0.2s;
  display: block;
 
  font-family:'Gill Sans Medium','Arial','Helvetica' , sans-serif
}
.menu.show .main_nav ul li.has_dropdown a{
  font-family:'Gill Sans Light','Arial','Helvetica' , sans-serif
}
.menu.show .main_nav ul li a:hover, .menu.show .main_nav ul li.has_dropdown:hover{
  color: #f2f0f0 !important;
}
.menu.show .overlay{
    background: #212121a6;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
}
.close_menu{
    position: absolute;
    right: 48px;
    color: #fff;
    top: 30px;
    background: #fa9133;
    padding: 5px 16px;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    line-height: 46px;
}

@media only screen and (max-width:1190px){
  .menu h4{
    position: absolute;
    width: 57%;
    max-width: none !important;
    top: 53%;
    font-size: 15px;
  }
}
/* top menu stylings */
.top_menu li {
    float: left;
    margin: 5px 20px;
    color: #1a0a60 !important;
    transition: 0.2s;
    font-size: 14px;
    text-transform: uppercase;
    list-style: none;
    font-weight: bold;
    font-family: 'Gill Sans SemiBold','Arial','Helvetica' , sans-serif !important;
    cursor: pointer;

}
.top_menu li a:hover, .top_menu li a.active{
  color: #fff !important
}
/* mobile menu css */
.mobile_buttons{
  margin-top: 20%;
  display: none;
  padding: 20px;
}
.mobile_buttons button{
  width: 100%;
  margin: 10px 0;
}
.mobile_buttons button a{
  color: #fff !important;
}
@media only screen and (max-width:1144px){
  .mobile_buttons{
   display: block
  }
 
}
.limited_space_text{
  transition: 0.5s;
}
@media only screen and (max-width:1270px){
 
  .limited_space_text{
    max-width: 25vw !important 
  
  }
}
@media only screen and (max-width:1105px){
 
  .limited_space_text{
    position: absolute;
    max-width: unset !important;
    top: 57px;
    width: 100vw;
  }
}
@media only screen and (max-width:600px){
 
  .limited_space_text{
    margin: 31px 10px !important;
    font-size: 18px !important;
  }
}


