.workshop_wrap{
    background: #1a0a60;
    color: #fff;
    padding: 100px;
    text-align: center;
    position: relative
}
@media only screen and (max-width:767px){
    
    .workshop_wrap{
        padding: 20px;
    }
}
.workshop_wrap object, .workshop_wrap svg{
    position: absolute;
    width: 290px;
    top: -3%;
    left: -232px;
    z-index: 0;
}
@media screen\0 {
    .workshop_wrap object, .workshop_wrap svg{
     height: 1102px;
    }
}
@media only screen and (max-width:1080px){
    
    .workshop_wrap object,.workshop_wrap svg{
    display: none;
    }
}

.counter{
    background: #f61a19;
    width: 125px;
    height: 125px;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 115px;
    color: #fff;
    font-family: 'Gill Sans SemiBold','Arial','Helvetica' , sans-serif;
    font-size: 80px;
    margin: auto;
}
@media only screen and (min-width:768px) and (max-width:1100px){
    
    .counter{
        width: 95px;
        height: 95px;
        line-height: 96px;
        font-size: 56px;
    }
}
.workshop_wrap h1{
    margin: 20px auto 60px;
}
.date_section{
    margin-top: 80px;
}
.date_section .button{
    min-width: 100px;
    min-height: unset;
    padding: 10px 35px;
    margin-top: 10px;
   
}
.button.booked{
    background: #941239;
}
.date_section p{
    font-size: 18px;
}
.date_section_item{
    border-right: 1px dashed #f89410;
    margin-bottom: 20px;
}
.date_section h3{
    font-size: 30px;
    margin-bottom: 0;
}
.date_section p{
    font-weight: 600;
    font-size: 24px;
}
.date_section_item:last-child{
    border: none;
}
@media only screen and (max-width:767px){
    .date_section_item{
        margin-bottom: 20px;
        border-bottom: 1px dashed #f89410;
        border-right: none;
        padding-bottom: 20px;
    }
}

.loader {
    color: #5c1284 !important;
}

.loader svg {
    position: unset !important;
    width: unset !important;
}
.red{
    color: #f61a19 !important
}

.speaker_text:nth-child(1){
  margin-bottom: 30px;
}  

@media only screen and (max-width:768px){
    .speaker_text:nth-child(1) {
            margin-bottom: 10px;
        }
        .speaker_text p {
            padding: 0 !important;
        }
}

.slotsMT {
    margin-top: 1.5rem !important;
}

@media only screen and (max-width:767px){
    .slotsMT {
        margin-top: 0 !important;
    }
}