.confirmationMessageWrap{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    z-index: 999;
    top: 0;
    font-family: Gill Sans Light,Arial,Helvetica,sans-serif!important;


    
}

@media only screen and (max-width:823px){
    .confirmationMessageWrap{
        top: 13vh;
        overflow: auto;

    }
    .return_btn{
        margin-bottom: 80px;
    }
}
.confirmationMessageWrap svg{
    color: #ff9400;
}
.content{
    max-width: 700px;
    margin: 7% auto !important;
    text-align: center;
    background: #5c1284;
    padding:30px 30px 20px;
    color: #fff;
    border-radius: 10px;
    opacity: 0;
    animation: pop-up 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    
}
@keyframes pop-up {
    0%{
        opacity: 0;
        margin: 10% auto;
    }
    100%{
        opacity: 1;
        margin: 15% auto;
    }
}