.custom_budge{
    padding: 0px 5px;
    font-weight: 100;
    margin: 0 5px 5px;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
    font-size: 17.75px;
    line-height: 30px;
}
.bordered_section{
    padding: 20px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #6c757d;
    font-family: 'Gill Sans Light','Arial','Helvetica' , sans-serif !important;
    font-size: 17.75px;
    line-height: 30px;
}
.bordered_section button{
    background: #1a0a60 !important;
    padding: 10px 15px !important;
    font-size: 12px !important;
    color: #fff !important;
    
}
.select_btn{
    padding: 10px 20px;
    font-size: 18px;
    font-family: 'Gill Sans Medium','Arial','Helvetica' , sans-serif;
    text-align: center;
    margin-bottom: 5px;
    background: #1a0a60;
    color: #fff;
    width: 100%;
    transition: 0.5s;
    margin-top: 10px;
}
.select_btn:hover{
background: #f61a19;
}
.select_btn.select_btn.active{
    background: #f61a19; 
}
.select_btn.active svg{
    transform: rotate(45deg);
   
}
.select_btn svg{
    font-size: 35px;
    transition: 0.5s;
    color: #fff;
}
.main_wrap{
    width: 75%;
    margin-left:12.5%;
    animation: slideBack 0.2s linear;
    animation-fill-mode: forwards;
}
.main_wrap.show{
  animation: slide 0.2s linear;
  animation-fill-mode: forwards;
  width: 100%
}
@keyframes slide 
{
    0%{
        margin-left:12.5%;
    }
  
    100%{
        margin-left: 0;
       
    }
}
@keyframes slideBack
{
    0%{
    
        margin-left: 0;
    }
  
    100%{
        margin-left:12.5%;
       
    }
}
.select_wrap{
    display: none;
}
.show .select_wrap{
    display: block;
    background: #cfcfcf;
    padding: 20px 30px;
    height: 100%;
    border-radius: 10px;
    position: relative;
    font-family: 'Gill Sans Medium','Arial','Helvetica' , sans-serif;
    max-height: 880px;
    overflow: auto;
}
.show .select_wrap::-webkit-scrollbar {
    width: 5px;         
  }
  @media only screen and (max-width: 600px){
    .show .select_wrap::-webkit-scrollbar {
      width: 5px;         
    }
  }
  .show .select_wrap::-webkit-scrollbar-track {
    background: #cfcfcf;  
    border: none;     
  }

 
  .show .select_wrap::-webkit-scrollbar-thumb {
    background-color: #f89410;   
    border-radius: 20px;       
    border: none;  
  }
.show .select_wrap::after{
    position: absolute;
    content: '';
    width: 35px;
    height: 35px;
    background: #cfcfcf;
    left: -15px;
    transform: rotate(45deg);
    border-radius: 6px;
    top: 160px;
}


@media only screen and (max-width: 767px){
    .show .select_wrap::after{
     
        left: 46%;
        top: auto;
        bottom: -15px;
    }
    
}