.faculty_wrap{
    background: #1a0a60;
    color:#fff;
    padding: 10px 0;
}


@media only screen and (min-width:1024px){
    .faculty_wrap .faculty_graphical_heading{
        padding: 50px 0 50px 130px !important;
        width: auto;
    }
}
.faculty_wrap h1{
      margin:0 auto 40px auto !important;
    background-position: 0% !important;
}


@media only screen and (max-width:768px){
    .faculty_wrap h1{
       width: 100%;
    }
} 

@media only screen and (min-width: 1200px){
    .container{
        max-width: 800px !important
    }
}

 @media only screen and (max-width: 767px){
    .faculty_wrap img {
    max-width: 250px;
    margin: 0 auto 15px;
}
} 
.faculty_wrap span{
    text-decoration: underline;
}
.faculty_wrap span:hover{
    text-decoration: none;
}