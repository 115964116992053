 /* Animation */
  
 @keyframes colorchange
 {
   0%   {background: #4f2d7f;}
   25%  {background: #ec008c;}
   50%  {background: #666;}
   75%  {background: #4f2d7f;}
   100% {background: #ec008c;}
 }
 @keyframes slideIn{
    0%{transform: scale(0);opacity: 0}
    100%{transform: scale(1);opacity: 1}
}
 @-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
 {
   0%   {background: #4f2d7f;}
   25%  {background: #ec008c;}
   50%  {background: #666;}
   75%  {background: #4f2d7f;}
   100% {background: #ec008c;}
 }
.loading, .loadingFailed {
    position: fixed;
    z-index: 30;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #333;
  }
 
  /* Transparent Overlay */
  .loading:before,  .loadingFailed:before {
    content: '';
    display: block;
    position: fixed;
    z-index: 40;
  
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: colorchange  10s infinite linear;
    -webkit-animation: colorchange 10s infinite linear;
  }
  .loadingFailed:before {
    animation: inherit;
    -webkit-animation:inherit;
    background: #4f2d7f;
  }
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
   
    border: 0;
  }
  
 


  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
  
  