.section1,.section2{
    text-align: center;
    min-height: 2vh;
    background: #e7e6ea;
    padding: 35px 0 0px;
    color: #1a0a60;
    font-weight: 600;
  

}
.programme_content hr{
    margin: 40PX;
    border-color: #f62518;
}
.programme_content img{
max-width: 165px;
margin:0 0 20px 0;
}
.section1 p{
    font-weight: 600;
    max-width: 1250px;
    margin: auto;
    
}

@media only screen and (max-width:767px){
    .section1,.section2,.section3, .section4{
        text-align: center !important;
    }
}
@media only screen and (min-width:1023px){
    .pi_text{
        position: absolute;
        width: 100%;
    }
}

.section1 img {
    max-width: 375px;
    margin: 50px 0;
   
}
.section2{
    background: #d6d6da;
    text-align: left;
    padding: 150px 0;
}
.section3{
    background:#e7e6ea;
    padding: 80px 0;
    text-align: left;
}
.section4{
    background:#d6d6da;
    padding: 80px 0;
    text-align: left;
}
.section5{
    background: #e7e6ea;
    padding: 80px 0;
    text-align: left;
}
.counter{
    border-radius: 50%;
    width: 130px;
    height: 130px;
    display: block;
    text-align: center;
    font-size: 60px;
    color: #fff;
    line-height: 115px;
    margin:10px  auto;
    font-family:'Gill Sans SemiBold','Arial','Helvetica' , sans-serif
}

@media only screen and (max-width:600px){
    .counter{
    width: 100px;
    height: 100px;
    font-size: 50px;
    line-height: 95px;
    }
}

.section2 .counter{
    background: #f61a19;
}
.section3 .counter{
    background: #f89410;
}
.section4 .counter{
    background: #1a0a60;
}
.section5 .counter{
    background: #66049d;
}
.section5 .button{
    background: #66049d;
}

#section2{
    position: relative;
   
}
#section2 .animation_path object, #section2 .animation_path svg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 14vh;
    right: 0;
    left: 4%;
}
@media only screen and (max-width:1366px){
    #section2 .animation_path object, #section2 .animation_path svg{
        width: 107%;
        height: 86%;
        position: absolute;
        top: 29vh;
        right: 0;
        background-position: center;
        left: 1%;
    }
}

@media only screen and (max-width:1080px){
    #section2 .animation_path object, #section2 .animation_path svg{
        display: none
    }
}

.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   right: 0;
   background: url(../../../../media/images/mhm2022/branding8.png);
    bottom: 0;
}
.icon2{
    left:0;
      background: url(../../../../media/images/mhm2022/branding6.png);   
      top: 5%
}
@media only screen and (max-width:1025px){
  .icon2, .icon1{
    display:none
}  
}

@media only screen and (min-width: 1200px) {
    .containerProgramme {
        max-width: 890px !important;
    }
}