.welcome_section_wrap{
    background:#1a0a60 ;
    text-align: center;
    color: #fff;
    padding: 30px 0 3px;
    position: relative;
    scroll-margin: 120px;
  z-index: 9;
}
@media only screen and (min-width:1367px){
    .welcome_section_wrap{
     
      min-height: 57vh;
 
    }
 
}
@media only screen and (max-width:1363px){
    .welcome_section_wrap{
         min-height: 79vh;
        scroll-margin: 10.5vh;
 
    }
  
}
@media only screen and (max-width: 1023px){
 
}
@media only screen and (max-width:767px){
    .welcome_section_wrap{
         min-height: 52vh;
        scroll-margin: 10.5vh;
 
    }
}
.path{
    height: 131%;
    position: absolute;
    right: 11%;
    width: 10%;
    top: -10%;
}
@media only screen and (max-width:1080px){
    .path{
        display: none
    }
}

.path svg{
    height: 100%;
}
@media screen\0 {
    .path svg{
     height: 1181px;
     width: 297px;
    }
}
.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   right: 0;
   background: url(../../../../media/images/mhm2022/branding5copy2.png);
}
.icon2{
   left:0;
      background: url(../../../../media/images/mhm2022/branding1copy3.png);
      bottom:0;
      width: 16vw;
      height: 45vh;
}

@media only screen and (max-width:1025px){
  .icon2, .icon1{
    display:none
}  
}
.dot2{
  
    top: 33%;
    right: -6%;
}
.dot3{
    top: 60%;
    right: 42%;
}
.welcome_section_wrap img{
    max-width: 375px;
    margin: 50px 0;
}
.welcome_section_wrap .button_section{
    display: grid;
    text-align: center;
}
.welcome_section_wrap .button{
    margin: 50px auto;
}

.welcome_header_text {
    font-size: 35px !important;
}