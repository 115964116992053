.webcast,.webcast_wrap{
    text-align: center;
    }
    .webcast img{
        max-width: 120px;
        margin: 15px;
    }
    .webcast h2{
    text-align: center;
    font-family:"Gill Sans Light",'Arial','Helvetica' , sans-serif !important;
    }

    .has_custom_border{
        margin-bottom: 30px;
        border-bottom: 2px solid #5c1284;
        padding: 30px;
        position: relative;
    }

.has_custom_border::after{
    position:absolute;
    bottom: -10px;
    height: 20px;
    width: 20px;
    background: #5c1284;
    border-radius: 50%;
    content: '';
    left: 90%;
}

.has_custom_border:nth-child(3)::after{
    left: 70%;
}
.has_custom_border:nth-child(3)::after{
    
    right: 25%;
}
.webcast_wrap button{
 background: #5c1284;
}
.webcast_wrap button:hover{

    box-shadow: inset 0px 0px 0px 5px #1a0a60;
}