.modal{
    visibility: hidden;
}

.active{
    visibility: visible;
    display: block;
    background: #1a0a60db;
    color: #1a0a60;
    overflow: auto;
    height: 100vh;
    scrollbar-width: 0px;      
  }
  .active::-webkit-scrollbar {
    width: 0px;         
  }
.modal_content h3{
  text-align: center !important;
}
.modal_content{
  text-align: center;
  background: #fff;
  border-radius: 30px;
  overflow: auto;
  padding: 30px 0 0;
  border: none;
  height: auto;
}
.modal_footer{
  position: unset;
  width: 100%;
  border: none;
}
.modal_dialog{
  min-width: 50% !important;
  margin: auto !important;
  position: fixed;
  left: 25%;
  width: auto;
}