.log_in_wrap{
    background: #f61a19;
    position: relative;
    overflow-x: hidden;
}

.log_in_wrap .logIn_card a{
    color: #fff !important;
}
.log_in_wrap .logIn_card h2 a{
    text-decoration: underline;
}
.log_in_wrap .logIn_card h2 a:hover{
    color: #f89410 !important;
    text-decoration: none !important;
}

.show{
    min-height: 92.6vh;
}
.logIn_card {
    text-align:center;
    color: #fff;
    border-radius: 10px;
    max-width: 100%;
    padding: 15px;
   margin: 0 auto;
    font-family: 'Gill Sans Medium','Arial','Helvetica' , sans-serif !important;
}

@media only screen and (max-width:600px){
    .logIn_card {
        margin: 0% auto 0;
        max-width: 100%;
    }
}
.logIn_card h3{
    color: #fff;
    font-size: 65px;
}
@media only screen and (max-width:1024px){
    .logIn_card h3 {
        margin: 15% auto 0;
    }
}
.log_in_wrap p{
    font-size: 18px;
}
@media only screen and (max-width:600px){
    .logIn_card h3{
       
        font-size: 18px;
    }
}

/* registration form section */
.registration_in_wrap{
    margin: 25% 0;
    text-align:center;
}
.registration_in_wrap .button{
    min-width: unset !important;
}
.registration_in_wrap h3{
    font-size: 30px;
    background-size: contain;
}

.thankyou{
    overflow: hidden;
    background: #1a0a60;
    min-height: 92.6vh;
    color: #fff;
    
}
.thankyou_wrap{
   margin: 30vh auto 10vh;
    text-align: center;
    max-width: 80%;
}
.thankyou_wrap a{
    color: #f61a19 !important;
}
.thankyou_wrap p{
   
}
.thankyou_wrap button{
    margin-top: 40px;
    background: #f61a19;
}
.thankyou_wrap button a{
    color: #fff !important;
}
.registerPage{
    min-height: 92.6vh;
}
.thankyou_wrap h3{
    font-size: 65px;
    margin: 45px 0;
}
.registerPage .curve1,.registerPage .curve2, .registerPage .curve3{
    display: block !important;
}
.registerPage .curve1,.registerPage .curve2, .registerPage .curve3{
    display: none !important;
}
@media only screen and (max-width: 767px){
    .curve1,.curve2,.curve3,.registercurve3{
  display: none;
}
}
.curve1,.curve2,.curve3,.registercurve3{
    position: absolute;
    top: 35%;
    left: -5%;
}
.curve1 img, .curve2 img{
    width: 15vw;
    margin: 0;
    max-width: unset;
}
.curve2 img{
    width: 20vw
}
.curve2 {
    left: auto;
    right: 0%;
    top: 18%;
}
.curve3,.registercurve3{
    left: auto;
    right: 15%;
    top: auto;
    /* bottom: 12%; */
    margin-top: 20px;
    
}
.curve3 img{
    width: 10vw;
}
.registercurve3{
    left: auto;
    right: 15%;
    top: auto;
    bottom: 12%;
    
}
.registercurve3 img{
    width: 10vw;
}
@media only screen and (max-width:767px){
    .registercurve3{
        bottom: 0;
    }
}

/* MY SESSIONS CSS */
.my_sessions_wrap{
    background: #140061;
    position: relative;
    padding: 5% 0;
    color: #fff

}
@media only screen  and (max-width:767px){
    .my_sessions_wrap {
        padding: 35% 0 5%;
    }
}
.my_sessions_wrap h2 {
    color: #f89410;
}
.my_sessions_wrap hr{
    border-color: #f89410
}
.my_sessions_wrap .yellow{
    background:#f89410;
    color:#fff !important;
        padding: 5px 30px;
    margin: 5px 0 0;
}
.my_sessions_wrap h3{
    color: #f62518;
}
.my_sessions_wrap a{
    color:#f89410 !important
}
.white
{
    color:#fff !important
}
.icon1, .icon2{
     position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.icon1{
   background: url(../../media/images/mhm2022/branding7.png);

   right: -9px;
   top: 5vh;
}
.icon2{
   background: url(../../media/images/branding3.png);
    left: 43px;
    top: 55vh;
    transform: rotate(-100deg);
}
@media only screen and (max-width:767px){
.icon1, .icon2{
    display:none
}  
}