.banner {
    opacity: 1;
    padding: 12% 100px 5%;
    background: #e6e6e7;
}

@media only screen and (max-width:1024px) {

    .banner {
        padding: 25% 100px 5%
    }
}

@media only screen and (min-width:1025px) and (max-width:1300px) {

    .banner {
        padding: 15% 100px 5%
    }
}

@media only screen and (max-width:600px) {

    .banner {
        padding: 50% 0px 5%
    }
}

.banner object,
.banner svg {
    position: absolute;
    width: 111%;
    top: 67%;
    left: 0vw;
}

@media screen\0 {

    .banner object,
    .banner svg {
        height: 810px;
    }
}

@media only screen and (max-width:1080px) {

    .banner object,
    .banner svg {
        display: none;
    }
}

.welcome_section {
    margin-bottom: 100px;
}

.curve1,
.curve2,
.curve3 {
    position: absolute;
    top: -45%;
    left: -14vw;
}

@media only screen and (min-width:1367px) {

    .curve1,
    .curve2,
    .curve3 {}
}

.curve1 img,
.curve2 img {
    width: 15vw;
    margin: 0;
    max-width: unset;
}

.counter span {
    background: #f61a19;
    width: 100px;
    display: block;
    margin: auto;
    height: 100px;
    font-size: 50px;
    line-height: 90px;
    border-radius: 50%;
    margin-bottom: 10px;
    z-index: 999;
    color: #fff;
    font-family: 'Gill Sans SemiBold', 'Arial', 'Helvetica', sans-serif !important;
}

.counter img {
    margin: 20px 0 20px 0;
}

.migration_education_section {
    padding: 50px;
    background: #d9d1e3;
    margin: 40px 0 20px;
    border-radius: 20px;
    position: relative;
    z-index: 9;
}

.migration_education_section button.btn {}

.migration_education_section::after {
    position: absolute;
    content: '';
    top: 5%;
    width: 98%;
    height: 90%;
    left: 1%;
    border-radius: 20px;
    border: 1px dashed #5c1284;
    z-index: -9;
}

.migration_education_section img {
    max-width: 130px;
    float: right;
}

@media only screen and (max-width:768px) {
    .migration_education_section img {

        float: none;
    }
}

.icon1,
.icon2 {
    position: absolute;
    width: 10vw;
    height: 35vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.icon1 {
    background: url(../../../../media/images/mhm2022/branding7.png);
    right: -9px;
    top: 42vh;
}

.icon2 {
    left: 0;
    background: url(../../../../media/images/mhm2022/branding6.png);
    top: 72vh;
}

@media only screen and (max-width: 1080px) {
    .icon2,
    .icon1 {
        width: 7vw;
    }
}


@media only screen and (max-width:1025px) {

    .icon2,
    .icon1 {
        display: none
    }

    .icon2{
        width: 9vw;
    }

    .counter img {
        width: 95%;
    }
}

@media only screen and (min-width:1250px) {
    .icon2{
        width: 7vw !important;
    }

}

.not_clickable {
    cursor: not-allowed;
    filter: grayscale(1);
    pointer-events: none;
}